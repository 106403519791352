import { create } from "zustand";
import $api from "../../../http";

const useMoveDbGoodStore = create((set) => ({
    newDistGood: null,
    moveGoodHandle: async (shopDbName, ids, distShopDbName, distGroupId) => {
        try{
            console.log(ids);
            const distShopDbName = this.distShopDbName;
            console.log(distShopDbName);
            /*
            const result = await $api.post('/shops/move-db-goods/goods', {
                distShopDbName, ids, distGroupId,
            }, {
                headers: { shopDbName }
            });
            set({newDistGood: result.data});
            */
        }
        catch(error) {
            console.log(error);
            set({newDistGood: null});
        }
    },
    distShopDbName: '',
    changeShopDbName: (shopDbName) => set({ distShopDbName: shopDbName }),
    distGroupId: '',
    changeGroupId: (groupId) => set({distGroupId: groupId}),
}));

export { useMoveDbGoodStore };