import { useEffect, useLayoutEffect, useState } from "react";
import $api from "../../http";
import { Select, Space, Table } from "antd";
import { useMoveDbGoodStore } from "./store/useMoveDbGoodStore";

const containerStyle = {
    width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px'
};

const defaultSelectValue = {label: '', value: ''};

const propsSelect = {
    style: {width: '150px', },
    size: 'small',
    defaultValue: {defaultSelectValue},
}
const propsTable = {
    size: 'small',
    pagination: false,
}

const getGroups = async (shopDbName) => {
    const result = await $api.get('/shops/manual/good-groups',{
        headers: {shopDbName}
    });
    const data = result.data.map(gr=>({value: gr.id + "", label: gr.name}));
    data.push({...defaultSelectValue});
    return data;
}


const getGoods = async (shopDbName, groupId) => {
    const resp = await $api.get(`/shops/manual/goods?isDeleted=false&limit=1000&groupId=${groupId}&isActual=true`,{
        headers: { shopDbName }
    });
    return resp.data.map(x=>({
        ...x, selected:false, compared: true,
    }));
}


function MoveDbGoodsRisghtTable({shops}){
    //const [distShopDbName, setDistShopDbName] = useState('');
    const [distGroups, setDistGroups] = useState([defaultSelectValue]);
    const [distGroupId, setDistGroupId] = useState('');
    const [distGoods, setDistGoods] = useState([]);
    const changeShopDbName = useMoveDbGoodStore((state)=>state.changeShopDbName);
    const shopDbName = useMoveDbGoodStore((state)=>state.distShopDbName);

    useEffect(()=>{
        getGroups(shopDbName).then(setDistGroups).catch(console.error);
    }, [shopDbName]);
    useEffect(()=>{
        getGoods(shopDbName, distGroupId).then(setDistGoods).catch(console.error);
    }, [distGroupId]);

    const distColumns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ед",
            dataIndex: "unitStr",
            key: "uuid"
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "uuid"
        },
    ];

    return <div>
        <div style={{padding: '10px'}}>
            <Space size={"small"}>
                Магазин <Select onChange={changeShopDbName} options={shops} {...propsSelect}/>
                Группа <Select onChange={setDistGroupId} options={distGroups} {...propsSelect}/>
            </Space>
        </div>
        <Table columns={distColumns} dataSource={distGoods} rowKey={x=>x.id} {...propsTable}/>
    </div>;
}

export default MoveDbGoodsRisghtTable;