import { Checkbox, Select, Space, Table } from "antd";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import $api from "../../http";
import MoveDbGoodsLeftTable from "./MoveDbGoodsLeftTable";
import MoveDbGoodsRisghtTable from "./MoveDbGoodsRisghtTable";

const containerStyle = {
    width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px'
};

const defaultSelectValue = {label: '', value: ''};

const getShops = async () => {
    const data = await $api.get("/shops/manual/shops");
    const result = data.data.map(x=>({label: x.name, value: x.shopDbName}));
    result.push({label: '', value: ''});
    return result;
}

const moveGood = async (shopDbName, distShopDbName, ids, distGroupId) => {
    const result = await $api.post('/shops/move-db-goods/goods', {
        distShopDbName, ids, distGroupId,
    }, {
        headers: { shopDbName }
    });
    return result.data;
}

function MoveDbGoods(){
    const [shops, setShops] = useState([defaultSelectValue]);

    useLayoutEffect(()=>{
        getShops().then(setShops).catch(console.error);
    }, []);

    return <div style={containerStyle}>
        <MoveDbGoodsLeftTable shops={shops}/>
        <MoveDbGoodsRisghtTable shops={shops}/>
    </div>
}

export default MoveDbGoods;