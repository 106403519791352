import { useEffect, useLayoutEffect, useState } from "react";
import $api from "../../http";
import { Button, Checkbox, Select, Space, Table } from "antd";
import { useMoveDbGoodStore } from "./store/useMoveDbGoodStore";

const defaultSelectValue = {label: '', value: ''};

const propsSelect = {
    style: {width: '150px', },
    size: 'small',
    defaultValue: {defaultSelectValue},
}
const propsTable = {
    size: 'small',
    pagination: false,
}

const getGroups = async (shopDbName) => {
    const result = await $api.get('/shops/manual/good-groups',{
        headers: {shopDbName}
    });
    const data = result.data.map(gr=>({value: gr.id + "", label: gr.name}));
    data.push({...defaultSelectValue});
    return data;
}

const getGoods = async (shopDbName, groupId) => {
    const result = await $api.get(`/shops/move-db-goods/goods?groupId=${groupId}&distShopDbName=Shop11`,{
        headers: {shopDbName}
    });
    return result.data.map(x=>({...x, selected: false}));
}



function MoveDbGoodsLeftTable({shops}){
    const [shopDbName, setShopDbName] = useState('');
    const [groups, setGroups] = useState([defaultSelectValue]);
    const [groupId, setGroupId] = useState('');
    const [goods, setGoods] = useState([]);
    const {moveGoodHandle} = useMoveDbGoodStore((state)=>state);

    useEffect(()=>{
        getGroups(shopDbName).then(setGroups).catch(console.error);
    }, [shopDbName]);

    useEffect(()=>{
        getGoods(shopDbName, groupId).then(setGoods).catch(console.error);
    }, [groupId]);

    const changeSelectedGood = (goodId)=> setGoods(prev=>prev.map(x=>{
        if(x.id===goodId) return {...x, selected: !x.selected};
        return {...x};
    }));

    const moveGood = () => {
        moveGoodHandle(shopDbName, goods.filter(x=>x.selected).map(x=>x.id));
    }

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (id, item) => <><Checkbox value={item.selected} onChange={_=>changeSelectedGood(id)} size='small'/></>
        },
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ед",
            dataIndex: "unitStr",
            key: "uuid"
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "uuid"
        },
        {
            title: '',
            dataIndex: 'action',
            render: (_, item) => <Button onClick={_=>moveGood()} size="small">-</Button>,
        }
    ];

    return <div>
        <div style={{padding: '10px'}}>
            <Space size={"small"}>
                Магазин <Select onChange={setShopDbName} options={shops} {...propsSelect}/>
                Группа <Select onChange={setGroupId} options={groups} {...propsSelect}/>
            </Space>
        </div>
        <Table columns={columns} dataSource={goods} rowKey={x=>x.id} rowClassName={x=>x.isCompare ? 'table-row-compared' : ''} {...propsTable}/>
    </div>;
}

export default MoveDbGoodsLeftTable;